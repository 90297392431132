<template>
  <div class="product-list-wrapper" style="min-height: 80vh">
    <section id="hero">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div v-for="(slider,key) in jsonParse(company_details.company_photos)" :class="key==0?'carousel-item active':'carousel-item'" :key="key">
            <img class="d-block w-100 img-fluid" style="max-height: 400px" :src="imgShow(slider)" alt="First slide">
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </section>

    <main id="main">
      <section id="about" class="about">
        <div data-aos="fade-up" class="container">
          <div class="row justify-content-end">
            <div class="col-lg-12">
              <div class="row justify-content-center">
               <div v-if="company_basic.website" class="col-lg-2 col-md-5 col-2 d-md-flex align-items-md-stretch">
                  <div class="count-box py-5">
                    <i class="fa fa-globe fa-4x" aria-hidden="true"></i>
                    <span data-toggle="counter-up"></span>
                    <p>{{ company_basic.website }}</p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-5 col-2 d-md-flex align-items-md-stretch">
                  <div class="count-box py-5">
                    <i class="fa fa-envelope fa-4x" aria-hidden="true" style="color: #3b5998"></i>
                    <span data-toggle="counter-up"></span>
                    <p>{{ company_basic.email }}</p>
                  </div>
                </div>
               <div class="col-lg-2 col-md-5 col-2 d-md-flex align-items-md-stretch">
                  <div class="count-box py-5">
                    <i class="fa fa-mobile fa-4x" style="color: #49a342" aria-hidden="true"></i>
                    <p>{{ company_basic.phone }}</p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-5 col-2 d-md-flex align-items-md-stretch">
                  <div class="count-box pb-5 pt-0 pt-lg-5">
                    <i class="fa fa-clock-o fa-4x" aria-hidden="true" style="color: #833ab4"></i>
                    <span data-toggle="counter-up"></span>
                    <p>{{ moment(moment().format()).diff(company_basic.establishment_date,"year") }} year of experience</p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-5 col-2 d-md-flex align-items-md-stretch">
                  <div class="count-box pb-5 pt-0 pt-lg-5">
                    <i class="fa fa-map-marker fa-4x" style="color: #58C9F3" aria-hidden="true"></i>
                    <span data-toggle="counter-up"></span>
                    <p>{{ company_basic.office_space }}</p>
                  </div>
                </div>
                <div class="col-lg-2 col-md-5 col-2 d-md-flex align-items-md-stretch" v-if="company_basic.is_verified===1">
                  <div class="count-box pb-5 pt-0 pt-lg-5">
                    <img :src="showImage('verified.png')" width="80%" height="80px" alt="verified" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
           <div v-if="company_details.company_video" class="col-lg-6 video-box align-self-baseline">
           <LazyYoutube :src="company_details.company_video"/>
           </div>
            <div class="col-lg-6 pt-3 pt-lg-0 content" :class="company_details.company_video?'':'col-md-12 col-lg-12'">
              <h3>About Us</h3>
              <div v-html="company_details.about_us"></div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section id="cta" class="cta">
        <div data-aos="fade-in" class="container">
          <div class="text-center">
            <h2 class="mb-4">Some Products</h2>
            <div class="col-md-12">
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="container">
                     <div class="row">
                      <div style="cursor: pointer" class="col-sm-3" v-for="(product,key) in products" @click="productLoad(product.slug)" :key="key">
                        <img :src="showImage(product.thumbnail_img)" :alt="product.name" class="img-thumbnail">
                        <p>{{product.name}}</p>
                        <p>
                          <strong>{{product.currency?product.currency.symbol:null }} {{ productPrice(product) }}</strong><br>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>

      <section id="services" class="services section-bg">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="icon-box" data-aos="fade-up">
                <div class="icon"><i class="las la-book" style="color: #e9bf06;"></i></div>
                <h4 class="title"><a href="javascript:void(0)">Our Mission</a></h4>
                <p class="description">{{company_details.mission}}</p>
              </div>
            </div>

            <div class="col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div class="icon-box">
                <div class="icon"><i class="las la-file-alt" style="color: #3fcdc7;"></i></div>
                <h4 class="title"><a href="javascript:void(0)">Our Vision</a></h4>
                <p class="description">{{company_details.vision}}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import ApiService from "../../core/services/api.service";
import { api_base_url } from "../../core/config/app";
import { LazyYoutube } from "vue-lazytube";
import moment from "moment";

export default {
  name: "CompanyProfile2",
  components: {
    LazyYoutube,
  },
  props: {
    display_name: {
      required: true,
    },
  },
  data() {
    return {
      moment,
      company_basic: {},
      company_details: {},
      products: {},
    };
  },
  created() {
    this.companyBasic();
    if (this.companyBasic) this.companyDetails();
    if (this.companyBasic) this.getProducts();
  },
  methods: {
    /*
     * method for image url set
     * */
    showImage(e) {
      return api_base_url + e;
    },
    /*
     * method for load clicked product information
     * */
    productLoad(slug) {
      this.$router.push({ name: "single.product", params: { slug: slug } });
    },
    /*
     * method for related product price rand calculate
     * */
    productPrice(product) {
      /*if (product.flash_deal_products.length>0){
        let flash = product.flash_deal_products.filter(item=>{
          if (item.flash_deal) return item;
        });
        if (flash.length>0) {
          return flash[flash.length - 1];
        }
      }else*/
      if (product.priceType == 0) {
        return product.unit_price;
      } else if (product.priceType == 1 && product.product_stock.length > 0) {
        let max = Math.max(...product.product_stock.map((d) => d.price));
        let min = Math.min(...product.product_stock.map((d) => d.price));
        return min + "-" + max;
      } else if (product.priceType == 2 && product.price_variation.length > 0) {
        let max = Math.max(...product.price_variation.map((d) => d.off_price));
        let min = Math.min(...product.price_variation.map((d) => d.off_price));
        return min + "-" + max;
      }
    },
    jsonParse(data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return [];
      }
    },
    imgShow(path) {
      return api_base_url + path;
    },
    async companyBasic() {
      await ApiService.get(
        `get/company/basic/info/by/display/name${this.display_name}`
      )
        .then((response) => {
          this.company_basic = response.data;
        })
        .catch(() => {
          this.company_basic = {};
        });
    },
    async companyDetails() {
      await ApiService.get(
        `get/company/details/by/user${this.company_basic.user_id}`
      )
        .then((response) => {
          this.company_details = response.data;
        })
        .catch(() => {
          this.company_details = {};
        });
    },
    async getProducts() {
      await ApiService.get(`company/product/by${this.company_basic.user_id}`)
        .then((response) => {
          this.products = response.data;
        })
        .catch(() => {
          this.products = {};
        });
    },
  },
  watch: {
    company_basic() {
      if (this.company_basic.user_id) this.companyDetails();
      if (this.company_basic.user_id) this.getProducts();
    },
  },
};
</script>

<style scoped>
div#carouselExampleControls a:hover {
    display: flex!important;
}

/* Icon box start */
.icon-box-style-one {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-box-style-one div {
    text-align: center;
    line-height: 30px;
}

.icon-box-style-one div i {
    font-size: 37px;
}

/* single product style */

.single-product-item {
    margin-bottom: 30px;
}

.single-product-item .product-img-box .hover {
    transform: translateY(100%);
}

.single-product-item:hover .product-img-box .hover {
    transform: translateY(-100%);
}

.single-product-item {
    position: relative;
}

.single-product-item .sale {
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    color: #fff;
    display: inline-block;
    padding: 3px 6px;
    background-color: #524a4b;
    position: absolute;
    top: 12px;
    z-index: 1;
    left: 12px;
}

.single-product-item .sale-product-img-box {
    width: 350px;
    height: 300px;
    background-size: cover;
}

.single-product-item .product-img-box .hover {
    background-color: #fff;
    padding: 12px 25px 12px;
    position: absolute;
    width: 100%;
    transform: translateY(100%);
    left: 0;
    z-index: 0;
    -webkit-transition: all linear 0.2s;
    transition: all linear 0.3s;
}

.single-product-item .product-img-box .hover .list {
    text-align: right;
}

.single-product-item .product-img-box .hover .list li {
    margin-bottom: 6px;
}

.single-product-item .product-img-box .hover .list li:last-child {
    margin-bottom: 0;
}

.single-product-item .product-img-box .hover .list li a {
    font-size: 16px;
    text-transform: initial;
    color: #999999;
    display: inline-block;
    -webkit-transition: all linear 0.2s;
    transition: all linear 0.2s;
    line-height: 25px;
}

.single-product-item .product-img-box .hover .list li a:hover {
    color: var(--main-color-one);
}

.single-product-item .product-img-box .hover .list li a .icon {
    display: inline-block;
    padding-left: 10px;
}

.single-product-item .product-details-wrap {
    padding: 20px 0 0;
}

.single-product-item .product-details-wrap .product-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-transform: initial;
    -webkit-transition: all linear 0.2s;
    transition: all linear 0.2s;
    color: #333333;
    margin: 0px!important;
}

.single-product-item .product-details-wrap .product-title:hover {
    color: var(--hover-color-one);
}

.single-product-item .product-details-wrap .product-price-details {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.single-product-item .product-details-wrap .product-price-details .list .price {
    display: inline-block;
    margin-right: 10px;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--main-color-one);
}

.single-product-item .product-details-wrap .product-price-details .category {
    text-transform: uppercase;
    color: #999999;
    font-size: 14px;
}

.single-product-item:hover .product-img-box .hover {
    transform: translateY(-100%);
}

.featured-area-wrapper {
    -webkit-box-shadow: 0px 0px 15px -6px #00000014;
    box-shadow: 0px 0px 15px -6px #00000014;
}

.featured-area-wrapper .featured-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.single-featured-item {
    text-align: center;
    position: relative;
    padding: 50px 22px 51px;
}

.featured-list .single-featured-item::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    width: 2px;
    background-color: #a09d9d1f;
    height: 60%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.featured-list .single-featured-item .icon-box .icon {
    color: #ddd;
    font-size: 45px;
    line-height: 55px;
}

.featured-list .single-featured-item .content .title {
    font-size: 22px;
    text-transform: capitalize;
    font-weight: 500;
    margin: 12px 0 16px;
    -webkit-transition: all linear 0.2s;
    transition: all linear 0.2s;
}

.featured-list .single-featured-item .content .title:hover {
    color: red;
}

.single-featured-item .content .info {
    color: #999999;
}

.single-featured-item:last-child::after {
    display: none;
}

.single-featured-item .icon-box .icon {
    color: #333 !important;
}

.single-featured-item .content .title:hover {
    color: #444;
}


.single-product-item .product-img-box {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.single-product-item .product-img-box .hover {
    background-color: #fff;
    padding: 12px 25px 12px;
    position: absolute;
    width: 100%;
    transform: translateY(100%);
    left: 0;
    z-index: 0;
    -webkit-transition: all linear 0.2s;
    transition: all linear 0.3s;
}

.single-product-item .product-img-box .hover .list {
    text-align: right;
}

.single-product-item .product-img-box .hover .list li {
    margin-bottom: 6px;
}

.single-product-item .product-img-box .hover .list li:last-child {
    margin-bottom: 0;
}

.single-product-item .product-img-box .hover .list li a {
    font-size: 16px;
    text-transform: initial;
    color: #999999;
    display: inline-block;
    -webkit-transition: all linear 0.2s;
    transition: all linear 0.2s;
    line-height: 25px;
}

.single-product-item .product-img-box .hover .list li a:hover {
    color: red;
}

.single-product-item .product-img-box .hover .list li a .icon {
    display: inline-block;
    padding-left: 10px;
}

.single-product-item .product-details-wrap {
    padding: 20px 0 0;
}

.single-product-item .product-details-wrap .product-title:hover {
    color: red;
}

.single-product-item .product-details-wrap .product-price-details {
    margin-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
}

.single-product-item .product-details-wrap .product-price-details .list .price {
    display: inline-block;
    margin-right: 10px;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: green;
}

.single-product-item .product-details-wrap .product-price-details .list .price del {
    color: #d22020;
    font-weight: 400;
}

.single-product-item .product-details-wrap .product-price-details .category {
    text-transform: uppercase;
    color: #999999;
    font-size: 14px;
}

.single-product-item:hover .product-img-box .hover {
    transform: translateY(-100%);
}

img {
    max-width: 100%;
    height: auto;
}

.single-product-item .product-details-wrap .product-title a {
    color: #333333;
    font-weight: 500;
}

.single-product-item .product-details-wrap .product-title:hover a {
    color: green;
}

.single-product-item .product-details-wrap .product-price-details .list {
    margin: 0px;
}

/* our services */
section#services .title a {
    font-size: 18px;
    color: green;
    font-weight: bold;
}

section#services .description {
    font-size: 14px;
    text-align: justify;
}

.font-weight-bold {
    font-weight: bold;
}
</style>